body {
  display: block;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.animated-gradient-text {
  font-size: 2.5em;
  font-weight: bold;
  background: linear-gradient(270deg, #ff6ec4, #7873f5, #3c82f4, #ffd700); /* Define gradient colors */
  background-size: 800% 800%; /* Make the background larger for a smoother animation */
  -webkit-background-clip: text; /* Clip the background to the text */
  -webkit-text-fill-color: transparent; /* Make the text transparent to show the background */
  animation: gradientAnimation 8s ease infinite; /* Apply animation */
  margin-bottom: 14px;
}



/* Gradient Animation Keyframes */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* Define the  button style */
.ant-btn {
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.ant-btn:hover {
  /* animation: gradientBackground 4s ease infinite;  */
  transform: scale(1.05); 
}


/* Define the button style variant-solid */
.ant-btn-variant-solid {
  color: #fff; 
  background: #9855FF; 
  border: none; 
  font-weight: 500; 
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease; 
  box-shadow: 0 4px 14px rgba(152, 85, 255, 0.3); 
  background-size: 200% 200%; 
}

/* Define hover state with gradient animation */
.ant-btn-variant-solid:hover {
  transform: scale(1.05) !important; 
}

/* Gradient animation keyframes */
@keyframes gradientBackground {
  0% {
    background-position: 0% 50% !important;
  }
  50% {
    background-position: 100% 50% !important;
  }
  100% {
    background-position: 0% 50% !important;
  }
}

